<template>
  <div class="studio wrapper">
    <a-spin :spinning="loading">
      <div class="">
        <a-empty v-if="studio.length == 0" description="暂无会议室"></a-empty>
        <div v-else class="flex flex-between studio-box-main">
          <template v-for="(item, index) in studio" :key="index">
            <div
              class="studio-item flex flex-between"
              @click="goToStudioDetail(item)"
            >
              <div class="flex">
                <img :src="item.studio_image" alt="" class="studio_image" />
                <div class="metting-info">
                  <div class="studio-title">{{ item.studio_name }}</div>
                  <div class="font-16 text-gray">
                    <div>{{ item.studio_address }}</div>
                    <div style="margin-top: 5px">
                      {{ transferWeek(item.studio_week)
                      }}{{ item.studio_start_time }}-{{
                        item.studio_end_time
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <right-outlined />
            </div>
          </template>
        </div>
        <div class="record-btn" @click="goStudioBookRecord">
          预订<br />记录
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getStudioList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserData } from "@/utils/storeData.js";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Studio",
  components: {
    RightOutlined
  },
  setup() {
    const pageData = reactive({
      studio: [],
      userData: computed(() => getUserData()),
      page: 1,
      limit: 16,
      total: 0,
      loading: false
    });
    const getStudioListFun = () => {
      pageData.loading = true;
      getStudioList({
        page: pageData.page,
        limit: pageData.limit,
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.studio = res.data.studio;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goToStudioDetail = _item => {
      url.navigateTo("/studio/detail", {
        t: encode(`${_item.studio_id}`)
      });
    };
    const goStudioBookRecord = () => {
      url.navigateTo("/studio/record");
    };
    const isContinuityNum = num => {
      let array = [];
      if (num instanceof Array) {
        array = [...num];
      } else {
        array = Array.from(num.toString()); //转换为数组
      }

      let i = array[0];
      let isContinuation = true;
      for (let e in array) {
        if (array[e] != i) {
          isContinuation = false;
          break;
        }
        i++;
      }
      return isContinuation;
    };
    const transferWeek = week => {
      let word = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let number = week.split(",").sort();
      let isContinuityWeek = "";
      let weekArr = "";
      let weekText = "";
      if (week) {
        isContinuityWeek = isContinuityNum(number);
        weekArr = number.map(v => {
          return word[v];
        });
        if (isContinuityWeek) {
          weekText = weekArr[0] + "至" + weekArr[weekArr.length - 1];
        } else {
          weekText = weekArr.join(",");
        }
        return weekText;
      }
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => pageData.page,
        () => pageData.limit
      ],
      val => {
        getStudioListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      goToStudioDetail,
      onShowSizeChange,
      transferWeek,
      goStudioBookRecord
    };
  }
});
</script>
<style lang="less" scoped>
.studio {
  padding-top: 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .studio-box-main {
    flex-wrap: wrap;
    .studio-item {
      width: 570px;
      padding: 30px 0;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;

      .studio_image {
        width: 180px;
        height: 130px;
        background-color: #1276cb;
        border-radius: 10px;
        margin-right: 30px;
      }
      .metting-info {
        display: flex;
        padding: 5px 0;
        flex-direction: column;
        justify-content: space-between;
        height: 130px;
        .studio-title {
          color: #212531;
          font-size: 20px;
        }
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
  .record-btn {
    width: 80px;
    height: 80px;
    background-color: #1276cb;
    border-radius: 80px;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    position: absolute;
    right: 0;
    bottom: -140px;
    cursor: pointer;
  }
}
</style>
